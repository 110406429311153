.test-body{
    background: #fff;
}

.test-body strong{
    color: #333;
}

.test-header{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 100;
}



/* @media screen and (min-width: 767px){
    .test-body .container-fluid{
        padding-left: 30px;
        padding-right: 30px;
    }
} */


.test-header ul{
    display: flex;
    -webkit-display: flex;
    border: 1px solid #ccc;
}

.test-header ul li{ 
    padding: 0.75rem 1.25rem;
    border-right: 1px solid #ccc;
    flex: auto;
    display: flex;
    -webkit-display: flex;
    justify-content: space-between;

}

.test-header a i{
    margin-left: 2rem;
}

.test-header ul li:first-child a{
    width: 100%;
}

.test-heading{
    margin-bottom: 30px;
}

.test-form img {
    max-width: 130px;
    margin-left: 1.875em;
}


.test-form{
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    height: 100%;
}

.test-content{
    background: #fff;
}

.test-content .row{
    height: 100vh;
}

.test-list{
    overflow: auto;
}

.test-list li{
    padding: 16px 0;
    border-bottom: 1px solid #ccc;
    padding-left: 2rem;
    display: flex;
    -webkit-display: flex;
    align-items: center;
}

.pagination-question > div{
    padding: 1rem 2rem;
}
.pagination-question > div:not(:last-child){
    border-right: 1px solid #ccc;
}

.pagination-question{
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    -webkit-display:flex;
    border-top: 1px solid #ccc;
    justify-content: space-between;
    background: #fff;
}


.pagination-question .pagination{
    width: 32vw;
    justify-content: space-between;
}
.pagination-question ul li{
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    flex: auto;
}

.pagination-question ul li .page-link{
    border: none;
    padding: 1rem 2rem;
    display: block;
    text-align: center;
}

.page-link.prev i{
    margin-right: 10px;
}
.page-link.next i{
    margin-left: 10px;
}

.test-question{
    padding-left: 2rem;
}

/* radio button counter*/

.cold input[type="radio"] {
    display: none;
    position: relative;
}
.cold input[type="radio"] + label {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
}
.cold:nth-of-type(1) input[type="radio"] + label::before {
    content: "1";
}
.cold:nth-of-type(2) input[type="radio"] + label::before {
    content: "2";
}
.cold:nth-of-type(3) input[type="radio"] + label::before {
    content: "3";
}
.cold:nth-of-type(4) input[type="radio"] + label::before {
    content: "4";
}
.cold input[type="radio"] + label::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    width: 32px;
    padding-left: 9px;
    border-radius: 100%;
    border: 1px solid rgb(174, 173, 173);
    display: flex;
    -webkit-display: flex;
    -ms-display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.cold input[type="radio"] + label::before {
    letter-spacing: 10px;
}
.cold input[type="radio"]:checked + label::before {
    background: #4980f9;
    border-color: #4980f9;
    color: #fff;
}

.pagination-question .page-link{
    color: inherit;
}


@media (min-width: 768px) and (max-width: 992px){
    .test-body, .test-body li, .test-body a, .test-body input{
        font-size: 0.875rem;
    }
    .test-header ul li{
        padding: 0.75rem 1.2rem;
    }
    .test-list li{
        padding-left: 1rem;
    }


}
 


@media screen and (max-width: 767px){
    .test-header ul li{
        border: 1px solid #ccc;
    }

    .test-header a{
        font-size: 13px;
    }

    .test-header ul li{
        padding: 0.5rem 1.25rem;
    }

    .test-form{
        margin-top: 40px;
    }
    
    .test-header ul{
        flex-wrap: wrap;
    }

    .pagination-question .pagination{
        width: 100%;
    }

}

.test-header .fa-arrow-left{
    margin-right: 8px;
}

.test-header  .fz{
    font-size: 1.125rem;
}


/*whole question*/
.question-avatar{
    display: flex;
    -webkit-display: flex;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    height: 64px;
    width: 64px;
    overflow: hidden;
    border: 1px solid #ccc;
}

.question-avatar img{
    max-width: inherit;
}

.question-right{
    border-left: 1px solid #ccc;
}

.question-item{
    padding: 0.5rem 1rem;
}

.question-right-top{
    display: flex;
    -webkit-display: flex;
    justify-content: space-between;
}

.question-right-bottom{
    display: flex;
    -webkit-display: flex;
    border-top: 1px solid #ccc;
    justify-content: space-between;
}

.question-query-list ul{
    display: flex;
    -webkit-display: flex;
}

.question-query-list{
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.question-query-list li{
    margin: 0.5rem 1rem;
}

.question-header{ 
    border-bottom: 1px solid #ccc;
}

.question-right{
    height: 100%;
}

.question-country{
    margin-top: 8px;
    margin-left: 1rem;
}

.question-avatar-wrap{
    display: flex;
    -webkit-display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.question-time{
    margin-right: 2rem;
    font-weight: bold;
    font-size: 1.125rem;
    display: flex;
    -webkit-display: flex;
    align-items: center;
}

.question-type-header{
    border: 1px solid #ccc;
    padding: 8px;
}

.numbers{
    
    display: flex;
    -webkit-display: flex;
    flex-wrap: wrap;
}

.question-type-header {
    margin-bottom: 8px;
    border-radius: 2px;
}

.question-type-header h4{
    margin-bottom: 0;
}

.numbers .number{
    padding: 8px;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    -webkit-display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 8px;
}

.numbers .number.active{
    background: #4980f9;
    border-color: #4980f9;
    color: #fff;
}

.question-type-item + .question-type-item{
    margin-top: 1rem;
}

.nav-tabs>li>a.active, .nav-tabs>li>a.active:focus, .nav-tabs>li>a.active:hover {
    color: #4980f9;
    border-bottom: 1px solid #337ab7;
}

.btn-fixed{
    width: 25;
    position: fixed;
    bottom: 30px;
    margin: 2rem;
}


 @media screen and (max-width: 767px){
    .question-right-top{
        border-top: 1px solid #ccc;
        margin-top: 20px;
        text-align: center;
    }

    .question-country{
        margin-right: 1rem;
    }

    .question-time{
        margin-left: 1rem;
    }
} 
