.image_holder input[type="file"]
{
    display: none;
}
.image_holder_rect input[type="file"]
{
    display: none;
}

.image_holder
{
    height: 200px;
    width: 200px;
    display: block;
    margin: auto;
}
.image_holder_rect
{
    height: 200px;
    width: 300px;
    display: block;
    margin: auto;
}